<template>
  <div class="container">
    <img
      class="grading-bg1-moblie"
      src="../assets/images/grading_bg_moblie.png"
      alt="grading_bg1"
      srcset=""
      v-if="isMobile"
    />
    <div class="title-box">
      <div class="title-en">GRADING RULES</div>
      <div class="title-zh">評選辦法</div>
    </div>

    <div class="content-box">
      <div class="table table1">
        <div class="table-title">國際組</div>
        <div class="content">
          <li>參賽企業營運狀況及經營願景</li>
          <li>參賽企業的商業模式及價值主張 / 營運方針或計畫</li>
          <li>參賽企業的產品／服務是否具有特殊國際競爭力</li>
          <li>參賽企業所提交海外拓展計劃的可行性</li>
          <div class="button">
            <a
              href="./singup1.docx"
              download="singup1.docx"
              class="table-download"
              target="_blank"
              >報名表下載</a
            >
          </div>
        </div>
      </div>

      <div class="table table2">
        <div class="table-title">永續組</div>
        <div class="content">
          <li>參賽企業營運狀況及經營願景</li>
          <li>參賽企業針對永續經營商業模式或環境永續的主張</li>
          <li>參賽企業是否開創符合經營永續或環保永續精神之嶄新商業模式</li>
          <li>
            參賽企業對其服務或產品在環保、能源管理或循環經濟等領域，是否有具體目標與作法
          </li>

          <div class="hint">※ 加分項目(永續組)：</div>

          <li>是否曾獲得環境永續相關標章或獎項</li>
          <li>過去一年是否因應環境氣候變遷風險而衍生新的業務或營收項目</li>
          <li>
            過去一年是否採用綠能或有具體省水、節電、減少排碳/溫室氣體等措施
          </li>
          <div class="button">
            <a
              href="./singup2.docx"
              download="singup2.docx"
              class="table-download"
              target="_blank"
              >報名表下載</a
            >
          </div>
        </div>
      </div>
    </div>

    <img
      class="gradin-bg1"
      src="../assets/images/grading_bg1.png"
      alt="grading_bg1"
      srcset=""
      v-if="!isMobile"
    />
  </div>
</template>

<script>
export default {
  name: "GradingRules",
  data() {
    return {
      isMobile: true,
    };
  },

  mounted() {
    this.onResize();

    window.addEventListener("resize", this.onResize, { passive: true });
  },

  methods: {
    onResize() {
      this.isMobile = window.innerWidth <= 1024;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/scss/_mixin.scss";
.container {
  width: 100%;
  position: relative;
  display: flex;
  margin-top: 3vw;
  // overflow: hidden;
  @media all and (max-width: 1024px) {
    flex-direction: column;
    margin-top: 2vw;
  }

  .grading-bg1-moblie {
    position: absolute;
    z-index: 0;
    width: 55%;
    top: -14vw;
  }
  .title-box {
    display: flex;
    width: 25%;
    justify-content: center;

    @media all and (max-width: 1024px) {
      width: 85%;
      flex-direction: column;
      margin: 0 auto;
      text-align: center;
      margin-bottom: 10vw;
      z-index: 2;
    }

    .title-zh {
      @include titleZH();
      @media all and (max-width: 1024px) {
        justify-content: center;
        text-align: center;
        display: flex;
      }
      &::after {
        @include titleAfter();
        height: 7vw;

        @media all and (max-width: 1920px) {
          height: 9vw;
        }

        @media all and (max-width: 1024px) {
          display: none;
        }
      }

      &::before {
        content: "";
        width: 24vw;
        display: none;
        margin-right: 6vw;
        border-top: 1px solid #fff;
        align-self: center;
        @media all and (max-width: 1024px) {
          display: inline-block;
        }
      }
    }

    .title-en {
      @include titleEN();

      @media all and (max-width: 1024px) {
        text-align: center;
      }
    }
  }
  .content-box {
    display: flex;
    width: 50%;
    justify-content: space-between;
    z-index: 2;
    @media all and (max-width: 1024px) {
      width: 85%;
      flex-direction: column;
      margin: 0 auto;
    }
    .table {
      width: 49%;
      display: flex;
      flex-direction: column;
      @media all and (max-width: 1024px) {
        width: 100%;
      }
      .table-title {
        letter-spacing: 0.15em;
        background-color: #8c9484;
        text-align: center;
        line-height: 3.4vw;
        border-radius: 0.5vw 0.5vw 0px 0px;
        margin-bottom: 0.3vw;
        @include subTitle();

        @media all and (max-width: 1024px) {
          font-size: 20px;
          line-height: 10vw;
          border-radius: 1.5vw 1.5vw 0px 0px;
          margin-bottom: 1vw;
        }
      }

      .content {
        background-image: url(../assets/images/grading_table_bg1.png);
        background-size: cover;
        padding-top: 1.5vw;
        padding-left: 1vw;
        padding-right: 1vw;
        height: 25vw;
        position: relative;
        border-radius: 0px 0px 0.5vw 0.5vw;

        @media all and (max-width: 1920px) {
          height: 30vw;
        }
        @media all and (max-width: 1440px) {
          height: 38vw;
        }

        @media all and (max-width: 1024px) {
          border-radius: 0px 0px 1.5vw 1.5vw;
          padding: 3vw 4vw;
          height: 100%;
          // background-image: none;
        }
        li {
          display: list-item;
          list-style: disc outside none;
          margin-left: 1vw;
          text-indent: -1.1vw;
          @include content();

          @media all and (max-width: 1024px) {
            margin-left: 6vw;
            text-indent: -5.9vw;
          }
        }

        .button {
          display: flex;
          justify-content: center;
          position: absolute;
          bottom: 2vw;
          left: 8.5vw;
          .table-download {
            background-color: #fbf1b9;
            @include buttonText();
            padding: 0.4vw 0.8vw;
            border: none;
            border-radius: 0.3vw;
            cursor: pointer;
            @media all and (max-width: 1024px) {
              border-radius: 1vw;
              padding: 0.8vw 2vw;
            }
          }

          @media all and (max-width: 1024px) {
            position: relative;
            bottom: 0vw;
            left: 0vw;
            margin-top: 5vw;
            margin-bottom: 3vw;
          }
        }
      }

      .hint {
        color: #bedeb5;
        @include pt16Text();
        font-weight: bold;
        letter-spacing: 0.1em;
        margin: 0.7vw 0vw;
        margin-top: 1.5vw;
        @media all and (max-width: 1024px) {
          font-size: 3.7vw;
          margin-top: 3vw;
        }
      }
    }

    .table2 {
      @media all and (max-width: 1024px) {
        margin-top: 8vw;
      }
      .content {
        background-image: url(../assets/images/grading_table_bg2.png);
        background-size: cover;
      }
    }
  }
  .gradin-bg1 {
    position: absolute;
    right: 0px;
    bottom: 0px;
    width: 18%;
  }
}
</style>
