import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import MemberView from '../views/MemberView.vue'
import singUpView from '../views/singUpView.vue'
import ForgetPwdView from '../views/ForgetPwdView.vue'
import NewsContent from '../views/NewsContent.vue'
import gsap from "gsap";

const routes = [

  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: { title: '龍騰微笑獎2024' },
  },
  {
    path: '/member',
    name: 'member',
    component: MemberView,
    meta: { title: '會員登入｜龍騰微笑獎2024' },
  },
  {
    path: '/singup',
    name: 'singup',
    component: singUpView,
    meta: { title: '報名｜龍騰微笑獎2024' },
  },
  {
    path: '/forget',
    name: 'forget',
    component: ForgetPwdView,
    meta: { title: '忘記密碼｜龍騰微笑獎2024' },
  },
  {
    path: '/news/:Tb_index',
    name: 'NewsContent',
    component: NewsContent,
    props: true, // 将路由参数作为组件的 props 传入
    meta: { title: '最新消息｜龍騰微笑獎2024' },
  },
  {
    path: '/:pathMatch(.*)',
    redirect: '/'
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior(to) {
    if (to.hash) return { el: to.hash, behavior: 'smooth' };
    return { top: 0, behavior: 'smooth' };
  }
})

router.beforeEach((to) => {
  let menu = document.querySelector('.moblie-menu-box')
  let tl = gsap.timeline({});
  tl.to(menu, {
    duration: 1,
    zIndex: "-1",
    height: "0vh",
    opacity: 0,
    ease: "power1.inOut",

  });
  document.title = to.meta.title || '龍騰微笑獎2024';
})
export default router
