<template>
  <div class="container">
    <div class="form-box">
      <div class="title-box">
        <div class="title">最新消息</div>
      </div>

      <div class="form-input-box" v-html="htmlContent"></div>
    </div>

    <div class="bg-logo-box" v-if="!isMobile">
      <img src="../assets/images/title.png" alt="title" srcset="" />
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "NewsContent",
  props: ["Tb_index"],
  data() {
    return { htmlContent: "" }; // 從後端獲取的 HTML 內容將會存儲在這個變量中};
  },

  mounted() {
    this.fetchHtmlContent();
  },

  methods: {
    fetchHtmlContent() {
      // console.log(this.$route.params.Tb_index);
      const tbIndex = this.$route.params.Tb_index;
      // 假設使用 Axios 調用後端 API，並在成功回調中將 HTML 內容賦值給 htmlContent
      axios
        .get(`https://back-longterm.srl.tw/ajax/news.php?id=${tbIndex}`)
        .then((res) => {
          // console.log(res.data.data, "res.data.data");
          this.htmlContent = res.data.data[0].aTXT; // 將後端返回的 HTML 內容賦值給 htmlContent
        })
        .catch((error) => {
          console.error("Error fetching HTML content:", error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/scss/_mixin.scss";

.container {
  width: 100%;
  position: relative;
  display: flex;
  padding: 10vw;

  padding-top: 8vw;
  margin: auto;

  @media all and (max-width: 1024px) {
    padding: 0vw;
    padding-top: 20vw;
  }

  @media all and (max-width: 500px) {
    height: auto;
    padding: 0vw;
    padding-top: 28vw;
  }

  color: #f9edda;
  font-family: "微軟正黑體";
  line-height: 1.5vw;
  text-align: justify;
  letter-spacing: 0.1em;

  font-size: 0.75vw !important;
  iframe {
    height: 36vw !important;
    @media all and (max-width: 1024px){
      height: 65vw !important;
    }
  }
  @media all and (max-width: 1024px) {
    line-height: 6.5vw;
  }
  .form-box {
    display: flex;
    width: 80%;
    margin: 0 auto;
    justify-content: center;
    flex-direction: column;

    @media all and (max-width: 1440px) {
      width: 100%;
    }

    .title-box {
      width: 80%;
      position: relative;
      text-align: center;
      margin: 0 auto;
      border-bottom: 1px solid #fbf1b9;
      padding-bottom: 1vw;
      margin-bottom: 3.5vw;
      display: flex;
      justify-content: center;
      @media all and (max-width: 1920px) {
        width: 70%;
      }

      @media all and (max-width: 1024px) {
        width: 85%;
        margin-bottom: 7vw;
        padding-bottom: 2vw;
      }

      .title {
        @include titleZH();
        writing-mode: inherit;
        color: #fbf1b9;
      }
    }

    .form-input-box {
      // display: flex;
      width: 100%;
      // flex-wrap: wrap;
      // flex-direction: column;
      margin: 0 auto;
      justify-content: center;

      p {
        span {
          color: #f9edda;
          font-family: "微軟正黑體";
          line-height: 1.5vw;
          text-align: justify;
          letter-spacing: 0.1em;

          font-size: 0.75vw !important;
        }
      }

      @media all and (max-width: 1024px) {
        width: 90%;
      }
      :deep(iframe) {
        height: 36vw !important;
        @media all and (max-width: 1024px){
            height: 65vw !important;
          }
      }
      :deep(p) {
        @include content();
      }
      :deep(li) {
        @include content();
      }
      :deep(h3) {
        @include content();
      }
      :deep(td) {
        background-color: rgba(81, 103, 101, 0.7882352941);
      }
      iframe {
          height: 36vw !important;
          
          @media all and (max-width: 1024px){
            height: 65vw !important;
          }
        }
    }
  }

  .bg-logo-box {
    position: fixed;
    right: 4vw;
    bottom: 3vw;
    img {
      width: 10vw;
      opacity: 0.5;
    }
  }
}
</style>
