<template>
  <div class="container">
    <div class="loading" v-if="loading">
      上傳中...請稍後
    </div>
    <div class="form-box">
      <div class="title">報名</div>
      <div class="form-input-box">
        <div class="input-box mem-type-box">
          <div class="input-title">報名組別</div>
          <select name="mem-type" id="" required v-model="singUpType" disabled>
            <option value="" selected>報名組別</option>
            <option value="1">永續組</option>
            <option value="2">國際組</option>
          </select>
        </div>
        <div class="input-box company-name-box">
          <div class="input-title">公司名稱</div>
          <input
            type="text"
            name="company-name"
            id=""
            placeholder="公司名稱"
            readonly
            disabled
            v-model="singUpCompanyName"
            required
          />
        </div>
        <div class="input-box mem-id-box">
          <div class="input-title">帳號(統一編號)</div>
          <input
            type="text"
            name="mem-id"
            id=""
            placeholder="帳號(統一編號)"
            readonly
            disabled
            v-model="singUpMemId"
            required
          />
        </div>

        <div class="input-box mem-file1-box">
          <div class="input-title">報名表</div>
          <label class="input-label">
            <input
              type="file"
              name="mem-file1-id"
              id=""
              required
              @change="handleFileChange"
              accept="application/msword, application/pdf, .docx"
              ref="fileInput"
            />
            <div class="input-file-btn">選擇檔案</div>

            <span>{{ pdfName1 }}</span>
            <a
              v-if="this.pdfLink1 !== ''"
              class="pdf-link"
              target="_blank"
              :href="pdfLink1"
              >檔案連結：{{ pdfLink1 }}</a
            >
          </label>
        </div>

        <div class="input-box mem-file2-box">
          <div class="input-title">公司簡介</div>
          <label class="input-label">
            <input
              type="file"
              name="mem-file2-id"
              id=""
              @change="handleFileChange2"
              accept="application/msword, application/pdf, .docx"
              required
              ref="fileInput2"
            />
            <div class="input-file-btn">選擇檔案</div>
            <span>{{ pdfName2 }}</span>
            <a
              v-if="this.pdfLink1 !== ''"
              class="pdf-link"
              target="_blank"
              :href="pdfLink2"
              >檔案連結：{{ pdfLink2 }}</a
            >
          </label>
        </div>
      </div>

      <div class="button-box">
        <div class="confirm-box">
          <button id="confirm-btn" @click="uploadFile">確定報名</button>
        </div>
      </div>
    </div>
    <div class="bg-logo-box" v-if="!isMobile">
      <img src="../assets/images/title.png" alt="title" srcset="" />
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import router from "@/router";
export default {
  name: "singUpView",
  data() {
    return {
      isMobile: true,
      singUpType: 1,
      singUpCompanyName: 0,
      singUpMemId: 0,
      singUpfile1: 0,
      singUpfile2: 0,

      pdfName1: "word、pdf檔", //預設
      pdfName2: "word、pdf檔", //預設
      pdfLink1: "", //上傳完成之後產出的連結
      pdfLink2: "", //上傳完成之後產出的連結

      loading:false
    };
  },

  mounted() {
    this.onResize();
    this.getMemberInfo();
    window.addEventListener("resize", this.onResize, { passive: true });
  },

  methods: {
    onResize() {
      this.isMobile = window.innerWidth <= 1024;
    },
    getMemberInfo() {
      if (
        sessionStorage.getItem("jwt") === null ||
        sessionStorage.getItem("jwt") === undefined
      ) {
        Swal.fire({
          title: "請先登入會員",
          icon: "error",
          background: "#f8ecd9",
          color: "#3e5a59",
          confirmButtonColor: "#3e5a59",
        });
        router.push("/member");
      } else {
        axios
          .get("https://back-longterm.srl.tw/ajax/member.php", {
            headers: {
              Authorization: `Bearer ${sessionStorage["jwt"]}`,
              "Refresh-Token": localStorage["refresh_jwt"],
            },
          })
          .then((res) => {
            if (res.data.success === true) {
              // console.log(res.data)
              this.isLogin = true;
              this.singUpMemId = res.data.data.mem_id;
              this.singUpType = res.data.data.mem_type;
              this.singUpCompanyName = res.data.data.company_name;
              this.pdfLink1 = res.data.data.mem_file1;
              this.pdfLink2 = res.data.data.mem_file2;
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },

    handleFileChange(event) {
      const fileList = event.target.files;
      this.pdfName1 = fileList[0].name;
      this.fileList = fileList;
    },

    handleFileChange2(event) {
      const fileList2 = event.target.files;
      this.pdfName2 = fileList2[0].name;
      this.fileList2 = fileList2;
    },

    uploadFile() {
      if ((!this.fileList || !this.fileList2) && (!this.pdfLink1 || !this.pdfLink2)) {
        Swal.fire({
          title: "請選擇檔案",
          icon: "warning",
          background: "#f8ecd9",
          color: "#3e5a59",
          confirmButtonColor: "#3e5a59",
        });
        return;
      }

      this.loading=true;

      const formData = new FormData();

      if(this.fileList!=undefined){
        formData.append("mem_file1", this.fileList[0]);
      }
      if(this.fileList2!=undefined){
        formData.append("mem_file2", this.fileList2[0]);
      }
      

      const headers = {
        Authorization: `Bearer ${sessionStorage["jwt"]}`,
        "Refresh-Token": localStorage["refresh_jwt"],
        "Content-Type": "multipart/form-data",
      };

      axios
        .post("https://back-longterm.srl.tw/ajax/apply.php", formData, {
          headers,
        })
        .then((response) => {
          console.log("File uploaded successfully:", response);
          if(response.data.success){
            Swal.fire({
                title: response.data.msg,
                icon: "success",
                background: "#f8ecd9",
                color: "#3e5a59",
                confirmButtonColor: "#3e5a59",
              }).then((result) => {
                if (result.isConfirmed) {
                  router.go();
                }
              });
          }
        })
        .catch((error) => {
          console.error("Error uploading file:", error);
          Swal.fire({
                title: `Error uploading file : ${error}`,
                icon: "error",
                background: "#f8ecd9",
                color: "#3e5a59",
                confirmButtonColor: "#3e5a59",
              })
        })
        .finally(() => {
          // 無論成功或失敗，都將 loading 設為 false
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/scss/_mixin.scss";

.container {
  width: 100%;
  position: relative;
  display: flex;
  padding: 10vw;
  margin: auto;
  // padding-bottom: 13vw;
  // padding-top: 11vw;
  height: 100vh;

  @media all and (max-width: 1024px) {
    height: auto;
    padding: 0vw;
    padding-top: 20vw;
  }

  @media all and (max-width: 500px) {
    height: auto;
    padding: 0vw;
    padding-top: 28vw;
  }

  .loading{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    background-color: #5e7775d1;
    backdrop-filter: blur(10px);
    z-index: 100;
    font-size: 19px;
    letter-spacing: 0.1em;
  }

  .form-box {
    display: flex;
    width: 80%;
    margin: 0 auto;
    justify-content: center;
    flex-direction: column;
    @media all and (max-width: 1440px) {
      width: 100%;
    }

    .title {
      width: 80%;
      @include titleZH();
      writing-mode: inherit;
      color: #fbf1b9;
      text-align: center;
      margin: 0 auto;
      border-bottom: 1px solid #fbf1b9;
      padding-bottom: 1vw;
      margin-bottom: 3.5vw;

      @media all and (max-width: 1920px) {
        width: 70%;
      }

      @media all and (max-width: 1024px) {
        width: 85%;
        margin-bottom: 7vw;
        padding-bottom: 2vw;
      }
    }

    .form-input-box {
      display: flex;
      width: 100%;
      flex-wrap: wrap;

      @media all and (max-width: 1920px) {
        width: 90%;
        margin: 0 auto;
      }
      @media all and (max-width: 1024px) {
        flex-direction: column;
      }
      .input-box {
        display: flex;
        width: 50%;
        margin-bottom: 2vw;
        padding: 0vw 2vw;

        @media all and (max-width: 1920px) {
          justify-content: space-evenly;
        }
        @media all and (max-width: 1024px) {
          width: 100%;
          justify-content: left;
          margin-bottom: 5vw;
        }
        .input-label {
          display: flex;
          width: 50%;
          flex-direction: row-reverse;
          flex-wrap: wrap;
          width: 70%;
          justify-content: space-evenly;
          position: relative;
          @media all and (max-width: 1024px) {
            flex-direction: column-reverse;
            width: 55%;
          }
          input {
            display: none;
          }
          .pdf-link {
            color: #f8ecd9;
            font-size: 0.65vw;
            letter-spacing: 0.1em;
            position: absolute;
            font-family: "微軟正黑體";
            bottom: -1vw;
            left: 1.1vw;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            width: 16vw;
            @media all and (max-width: 1440px) {
              font-size: 0.75vw;
            }
            @media all and (max-width: 1024px) {
              font-size: 3.5vw;
            }

            @media all and (max-width: 500px) {
              bottom: -5.5vw;
              width: 40vw;
            }
          }
          .input-file-btn {
            display: flex;
            background-color: #3e5a59;
            @include content();
            font-weight: 600;
            color: #fff;
            cursor: pointer;
            padding: 0vw 1vw;
            border-radius: 0.3vw;

            @media all and (max-width: 1920px) {
              border-radius: 0.45vw;
            }

            @media all and (max-width: 1440px) {
              line-height: 2.2vw;
            }

            @media all and (max-width: 1024px) {
              line-height: 7vw;
              width: 21vw;
              text-align: center;
              display: block;
              border-radius: 1.2vw;
              margin-top: 1.5vw;
            }
          }

          span {
            @include content();
            color: #3e5a59;
            font-weight: 600;
            background-color: #f8ecd9;
            border-radius: 0.3vw;
            padding: 0vw 1vw;
            width: 55%;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            @media all and (max-width: 1920px) {
              border-radius: 0.45vw;
              width: 58%;
            }

            @media all and (max-width: 1440px) {
              padding-top: 0.1vw;
            }

            @media all and (max-width: 1024px) {
              width: 100%;
              border-radius: 1.2vw;
              padding: 0vw 3vw;
              line-height: 7vw;
            }
            @media all and (max-width: 500px) {
              padding: 1.5vw 3vw;
            }
          }
        }
        .input-title {
          @include dateText();
          color: #f8ecd9;
          font-weight: 600;
          width: 35%;

          @media all and (max-width: 1024px) {
            width: 45%;
          }

          @media all and (max-width: 500px) {
            align-self: center;
          }
        }
        :disabled {
          opacity: 0.7;
        }

        input {
          width: 40%;
          background-color: #f8ecd9;
          @include content();
          color: #3e5a59;
          font-weight: 600;
          border: none;
          border-radius: 0.3vw;
          padding: 0vw 1vw;
          &::placeholder {
            @include content();
            color: #3e5a59cc;
            font-weight: 600;
          }

          @media all and (max-width: 1920px) {
            width: 45%;
            border-radius: 0.45vw;
          }

          @media all and (max-width: 1024px) {
            width: 55%;
            border-radius: 1.2vw;
            padding: 0vw 3vw;
            line-height: 7vw;
          }

          @media all and (max-width: 500px) {
            padding: 1.5vw 3vw;
          }
        }

        select {
          width: 40%;
          background-color: #f8ecd9;
          @include content();
          color: #3e5a59;
          font-weight: 600;
          border: none;
          border-radius: 0.3vw;
          padding: 0vw 1vw;
          appearance: none;

          @media all and (max-width: 1920px) {
            width: 45%;
            border-radius: 0.45vw;
          }

          @media all and (max-width: 1024px) {
            width: 55%;
            border-radius: 1.2vw;
            padding: 0vw 3vw;
          }

          @media all and (max-width: 500px) {
            padding: 1.5vw 3vw;
          }
        }
      }

      .mem-type-box {
        order: 1;
      }

      .company-name-box {
        order: 3;
        @media all and (max-width: 1024px) {
          order: 2;
        }
      }

      .mem-id-box {
        order: 5;
        @media all and (max-width: 1024px) {
          order: 3;
        }
      }

      .mem-file1-box {
        order: 2;
        @media all and (max-width: 1024px) {
          order: 4;
        }

        @media all and (max-width: 500px) {
          padding-bottom: 5vw;
        }
        .input-title {
          width: 25%;
          @media all and (max-width: 1920px) {
            width: 45%;
          }

          @media all and (max-width: 500px) {
            align-self: baseline;
            padding-top: 2vw;
          }
        }
      }

      .mem-file2-box {
        order: 4;
        @media all and (max-width: 1024px) {
          order: 5;
        }

        @media all and (max-width: 500px) {
          padding-bottom: 5vw;
        }
        .input-title {
          width: 25%;
          @media all and (max-width: 1920px) {
            width: 45%;
          }
          @media all and (max-width: 500px) {
            align-self: baseline;
            padding-top: 2vw;
          }
        }
      }
    }

    .button-box {
      display: flex;
      width: 100%;
      justify-content: center;
      margin-top: 5vw;

      @media all and (max-width: 500px) {
        margin-top: 9vw;
      }
      .cancel-box {
        #cancel-btn {
          @include buttonText();
          width: 9vw;
          color: #fff;
          background: none;
          border: 1px solid #fff;
          border-radius: 0.3vw;
          line-height: 2vw;
          cursor: pointer;

          @media all and (max-width: 1920px) {
            border-radius: 0.45vw;
          }

          @media all and (max-width: 1440px) {
            line-height: 2.2vw;
          }

          @media all and (max-width: 1024px) {
            width: 28vw;
            line-height: 7vw;
            border-radius: 1.2vw;
          }

          @media all and (max-width: 500px) {
            line-height: 8.7vw;
            width: 30vw;
          }
        }
      }

      .confirm-box {
        margin-left: 2vw;
        @media all and (max-width: 500px) {
          margin-left: 5vw;
        }
        #confirm-btn {
          @include buttonText();
          width: 9vw;
          border: none;
          background-color: #fbf1b9;
          border-radius: 0.3vw;
          line-height: 2vw;
          cursor: pointer;

          @media all and (max-width: 1920px) {
            border-radius: 0.45vw;
          }

          @media all and (max-width: 1440px) {
            line-height: 2.2vw;
          }

          @media all and (max-width: 1024px) {
            width: 28vw;
            line-height: 7vw;
            border-radius: 1.2vw;
          }

          @media all and (max-width: 500px) {
            line-height: 9vw;
            width: 30vw;
          }
        }
      }
    }
  }

  .bg-logo-box {
    position: absolute;
    right: 4vw;
    bottom: 3vw;
    img {
      width: 10vw;
      opacity: 0.5;
    }
  }
}
</style>
