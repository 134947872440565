<template>
  <div class="container" id="videobanner">
    <img
      class="grading-bg2"
      src="../assets/images/grading_bg2.png"
      alt="grading_bg2"
      srcset=""
    />
    <div class="title-box">
      <div class="title-en">FEATURED VIDEOS</div>
      <div class="title-zh">影片專區</div>
    </div>

    <div class="main-banner-box">
      <swiper-container
        class="swiper main-swiper position-relative"
        ref="myMainSwiper"
        v-if="!isMobile"
      >
        <swiper-slide
          v-for="(item, index) in videoUrl"
          :key="index"
          :item="item"
        >
          <div v-html="item.YT_url"></div>
        </swiper-slide>

        <!-- <swiper-slide>
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/ABxzzG85wxM"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;"
            web-share
            allowfullscreen
          ></iframe>
        </swiper-slide>
        <swiper-slide>
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/V3BgVuc4IzQ"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;"
            web-share
            allowfullscreen
          ></iframe>
        </swiper-slide> -->
      </swiper-container>
      <swiper-container
        class="swiper swiper2"
        :direction="'vertical'"
        :slidesPerView="3"
        :spaceBetween="100"
        ref="mySwiper"
        v-if="!isMobile"
        :breakpoints="{
          2000: {
            slidesPerView: 3,
            spaceBetween: 100,
          },

          1920: {
            slidesPerView: 3,
            spaceBetween: 55,
          },

          1440: {
            slidesPerView: 3,
            spaceBetween: 43,
          },

          1400: {
            slidesPerView: 3,
            spaceBetween: 43,
          },

          1366: {
            slidesPerView: 3,
            spaceBetween: 40,
          },

          1280: {
            slidesPerView: 3,
            spaceBetween: 40,
          },
        }"
      >
        <swiper-slide
          v-for="(item, index) in videoUrl"
          :key="index"
          :item="item"
          class="swiper2-slide"
          @click="onClickSlide(index)"
          :centeredSlides="true"
        >
          <img
            :src="'https://back-longterm.srl.tw/img/' + item.pc_img"
            alt=""
            srcset=""
          />
        </swiper-slide>
      </swiper-container>

      <!-- 手機版 -->
      <swiper-container
        class="swiper moblieSwiper position-relative"
        ref="moblieSwiper"
        v-if="isMobile"
      >
        <swiper-slide
          v-for="(item, index) in videoUrl"
          :key="index"
          :item="item"
        >
          <div v-html="item.YT_url"></div>
        </swiper-slide>

        <!-- <swiper-slide>
          <iframe
            src="https://www.youtube.com/embed/3KnMzd3POJQ"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe>
        </swiper-slide>
        <swiper-slide>
          <iframe
            src="https://www.youtube.com/embed/3KnMzd3POJQ"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe>
        </swiper-slide> -->
      </swiper-container>
    </div>

    <div class="banner-main-pagination">
      <div class="pagination-box" v-if="!isMobile">
        <div
          class="pagination"
          v-for="(item, index) in imgLength"
          :key="index"
          :item="item"
          :class="{ paginationActive: index === thumbsIndex }"
        ></div>
      </div>

      <div class="pagination-box" v-if="isMobile">
        <div
          class="pagination"
          v-for="(item, index) in imgLength"
          :key="index"
          :item="item"
          @click="onMoblieClickSlide(index)"
          :class="{ paginationActive: index === moblieSwiperIndex }"
        ></div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";

export default {
  name: "VideoBanner",

  data() {
    return {
      imgUrl: [{}],
      videoUrl: [{}],

      thumbsIndex: 0,
      moblieSwiperIndex: 0,
      imgLength: 0,
      paginationActive: 0,
      isMobile: true,
    };
  },
  created() {
    this.getViedo();
  },
  computed: {
    // // thumbs swiper
    swiper() {
      return this.$refs.mySwiper.swiper;
    },
    // //main swiper
    swiperMain() {
      return this.$refs.myMainSwiper.swiper;
    },
  },
  mounted() {
    this.onResize();
    window.addEventListener("resize", this.onResize, { passive: true });

    const moblieSwiper = this.$refs.moblieSwiper.swiper;
    this.moblieSwiperIndex = this.$refs.moblieSwiper.swiper.realIndex;
    moblieSwiper.on("slideChange", () => {
      this.moblieSwiperIndex = this.$refs.moblieSwiper.swiper.realIndex;
    });
  },
  methods: {
    getViedo() {
      axios
        .get("https://back-longterm.srl.tw/ajax/index.php?type=get_video", {})
        .then((res) => {
          this.imgUrl = Object.freeze(res.data);
          this.videoUrl = Object.freeze(this.imgUrl.data);
          this.imgLength = this.videoUrl.length;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // click thumbs swiper slide change main swiper
    onClickSlide(index) {
      this.thumbsIndex = index;
      this.$refs.myMainSwiper.swiper.slideTo(index);
      // swiper-slide-active
    },
    onMoblieClickSlide(index) {
      this.moblieSwiperIndex = index;
      this.$refs.moblieSwiper.swiper.slideTo(index);
    },
    onResize() {
      this.isMobile = window.innerWidth <= 1024;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/scss/_mixin.scss";

.container {
  width: 100%;
  position: relative;
  display: flex;
  padding-top: 8vw;
  overflow: hidden;
  flex-direction: column;
  margin-bottom: 8vw;

  @media all and (max-width: 1024px) {
    margin-top: 3vw;
  }

  .title-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 50%;
    margin: 0 auto;
    @media all and (max-width: 1024px) {
      width: 85%;
      flex-direction: column;
      margin: 0 auto;
      text-align: center;
      margin-bottom: 6vw;
      z-index: 2;
    }

    .title-zh {
      @include titleZH();
      writing-mode: horizontal-tb;
      text-align: left;
      @media all and (max-width: 1024px) {
        justify-content: center;
        text-align: center;
        display: flex;
      }
      &::before {
        content: "";
        display: inline-block;
        border-bottom: 2px solid #ffffff;
        transform: translateY(-1rem);
        margin-right: 4.3vw;
        width: 8vw;
        @media all and (max-width: 1024px) {
          content: "";
          width: 24vw;
          display: none;
          margin-right: 6vw;
          border-bottom: none;
          transform: translateY(0rem);
          border-top: 1px solid #fff;
          align-self: center;
          display: inline-block;
        }
      }
    }

    .title-en {
      @include titleEN();
      writing-mode: horizontal-tb;
      text-align: left;
      margin-bottom: 0.5vw;

      @media all and (max-width: 1024px) {
        text-align: center;
      }
    }
  }
  .main-banner-box {
    display: flex;
    width: 50%;
    justify-content: center;
    margin: 0 auto;
    margin-top: 4vw;

    @media all and (max-width: 1024px) {
      width: 85%;
    }

    :deep(iframe) {
      width: 38vw;
      height: 20vw;

      @media all and (max-width: 1024px) {
        width: 86vw;
        height: 50vw;
      }
    }
    .swiper {
      width: 80%;
      padding-bottom: 1vw;
      margin-right: 1vw;
      @media all and (max-width: 1024px) {
        margin-right: 0vw;
        width: 100%;
        margin-left: 0vw;
        padding-bottom: 4vw;
      }
      img {
        width: 39vw;
        // height: 20vw;

        @media all and (max-width: 1024px) {
          width: 100vw;
        }
      }
      .yt-player-iframe {
        width: 38vw;
        height: 20vw;

        @media all and (max-width: 1024px) {
          width: 86vw;
          height: 50vw;
        }
      }
      .swiper-pagination {
        .swiper-pagination-bullet {
          background: #f9edda !important;
        }
      }
    }

    .swiper2 {
      width: 20%;
      height: 20.5vw;

      .swiper2-slide {
        img {
          width: 10vw;
          cursor: pointer;
          // height: 6vw;
        }
      }
    }
  }

  .banner-main-pagination {
    display: flex;
    width: 50%;
    justify-content: left;
    margin: 0 auto;
    // height: 10vw;
    @media all and (max-width: 1024px) {
      width: 85%;
    }

    .pagination-box {
      display: flex;
      width: 75%;
      justify-content: center;
      @media all and (max-width: 1920px) {
        width: 80%;
      }
      @media all and (max-width: 1024px) {
        width: 100%;
      }
      .pagination {
        position: relative;
        // width: 70px;
        // height: 10px;
        width: 2.8vw;
        height: 0.4vw;
        border: 1px solid #f9edda;
        border-radius: 50px;
        margin-right: 0.7vw;

        @media all and (max-width: 1024px) {
          width: 6.5vw;
          height: 1.1vw;
          margin-right: 2vw;
        }
      }
      .paginationActive {
        background-color: #bad9b1;
        border: none;
      }
    }
  }
  .grading-bg2 {
    position: absolute;
    right: 0px;
    top: 0px;
    width: 25%;
  }
}
</style>
