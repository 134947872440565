<template>
  <nav class="header-box">
    <div class="logo-box">
      <router-link to="/">
        <img src="./assets/images/header_svg.svg" alt="header_svg" srcset="" />
      </router-link>
    </div>
    <div class="link-box" v-if="!isMobile">
      <div class="link">
        <router-link to="/">關於競賽</router-link>
      </div>
      <div class="link">
        <router-link :to="{ path: '/', hash: '#timecourse' }"
          >競賽時程</router-link
        >
      </div>
      <div class="link">
        <router-link :to="{ path: '/', hash: '#contestrules' }"
          >競賽辦法</router-link
        >
      </div>
      <div class="link">
        <router-link :to="{ path: '/', hash: '#videobanner' }"
          >影片專區</router-link
        >
      </div>
      <div class="link">
        <router-link :to="{ path: '/', hash: '#reviweteam' }"
          >評審團</router-link
        >
      </div>
      <div class="link member-link">
        <router-link to="/member">
          <img src="./assets/images/member.png" alt="member" srcset="" />
        </router-link>
      </div>
      <!-- <div class="link sign-up-link">
        <router-link to="/singup">報名</router-link>
      </div> -->
    </div>

    <div class="moblie-link-box" v-if="isMobile">
      <div class="link member-link">
        <router-link to="/member">
          <img src="./assets/images/member.png" alt="member" srcset="" />
        </router-link>
      </div>
      <!-- <div class="link sign-up-link">
        <router-link to="/singup">報名</router-link>
      </div> -->

      <div class="link menu-btn" @click="onClickMenuBtn()" ref="menuBtn">
        <img src="./assets/images/menu_btn.png" alt="" srcset="" />
      </div>
    </div>
  </nav>

  <div
    class="moblie-menu-box"
    v-if="isMobile"
    ref="moblieMenu"
    v-show="this.isMenuOpen == true"
  >
    <img
      class="close-menu"
      src="./assets/images/close_menu.png"
      alt=""
      srcset=""
      @click="onClickCloseMenuBtn()"
    />
    <router-link to="/">關於競賽</router-link>
    <router-link :to="{ path: '/', hash: '#timecourse' }">競賽時程</router-link>
    <router-link :to="{ path: '/', hash: '#contestrules' }">競賽辦法</router-link>
    <router-link :to="{ path: '/', hash: '#videobanner' }">影片專區</router-link>
    <router-link :to="{ path: '/', hash: '#reviweteam' }">評審團</router-link>
  </div>

  <router-view />
  
</template>
<script>
import gsap from "gsap";

export default {
  data() {
    return {
      isMobile: true,
      isMenuOpen: false,
    };
  },

  mounted() {
    this.onResize();
    window.addEventListener("resize", this.onResize, { passive: true });
  },

  methods: {
    onResize() {
      this.isMobile = window.innerWidth <= 1024;
    },

    onClickMenuBtn() {
      this.isMenuOpen = true;

      let tl = gsap.timeline({});
      tl.to(this.$refs.moblieMenu, {
        duration: 1,
        zIndex: 9999,
        height: "100vh",
        opacity: 1,
        ease: "power1.inOut",
      });
    },

    onClickCloseMenuBtn() {
      let tl = gsap.timeline({});
      tl.to(this.$refs.moblieMenu, {
        duration: 1,
        zIndex: "-1",
        height: "0vh",
        opacity: 0,
        ease: "power1.inOut",
        onComplete: () => {
          this.isMenuOpen = false;
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./assets/scss/mixin";
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
  list-style: none;
}

nav {
  display: flex;
  justify-content: space-between;
  background-color: #516765c9;
  backdrop-filter: blur(10px);
  padding: 0.7vw 4vw;
  position: fixed;
  z-index: 99;
  top: 0px;
  width: 100%;
  left: 0px;

  @media all and (max-width: 1680px) {
    padding-top: 0.8vw;
  }

  @media all and (max-width: 1024px) {
    padding: 1.5vw 6vw;
  }
  @media all and (max-width: 500px) {
    padding: 4vw 6vw;
  }
  .logo-box {
    width: 7vw;
    display: flex;
    a {
      display: flex;
    }
    @media all and (max-width: 1680px) {
      width: 10vw;
    }

    @media all and (max-width: 1024px) {
      width: 21vw;
    }

    @media all and (max-width: 500px) {
      width: 27vw;
    }

    img {
      width: 100%;
      align-self: center;
    }
  }

  .link-box {
    display: flex;
    text-align: center;
    align-self: center;
    .link {
      margin-left: 2vw;

      @media all and (max-width: 1440px) {
        margin-left: 2.5vw;
      }
      a {
        font-family: "微軟正黑體";
        @include content();
        color: white;
      }
    }

    .member-link {
      width: 1vw;
      @media all and (max-width: 1680px) {
        width: 1.5vw;
      }
      img {
        width: 100%;
        display: inline-block;
        vertical-align: sub;
      }
    }

    .sign-up-link {
      background-color: #fbf1b9;
      border-radius: 0.4vw;
      padding: 0vw 0.6vw;

      @media all and (max-width: 1440px) {
        padding: 0vw 0.9vw;
      }
      a {
        color: #3e5a59;
        font-weight: bolder;
      }
    }
  }

  .moblie-link-box {
    display: flex;
    .link {
      margin-left: 2vw;

      @media all and (max-width: 1440px) {
        margin-left: 2.5vw;
      }

      @media all and (max-width: 1024px) {
        margin-left: 4vw;
      }
      a {
        font-family: "微軟正黑體";
        @include content();
        color: white;
      }
    }

    .member-link {
      width: 3.5vw;

      img {
        width: 100%;
        display: inline-block;
        vertical-align: bottom;

        @media all and (max-width: 500px) {
          vertical-align: sub;
        }
      }
    }

    .sign-up-link {
      background-color: #fbf1b9;
      border-radius: 0.4vw;
      padding: 0vw 0.6vw;

      @media all and (max-width: 1440px) {
        padding: 0vw 0.9vw;
      }
      @media all and (max-width: 1024px) {
        padding: 1.2vw 2vw;
        border-radius: 1.5vw;
        align-self: center;
      }

      @media all and (max-width: 500px) {
        padding: 1vw 2vw;
      }
      a {
        color: #3e5a59;
        font-weight: bolder;
        line-height: 4vw;
      }
    }

    .menu-btn {
      width: 6vw;
      display: flex;
      img {
        width: 100%;
        align-self: center;
      }
    }
  }
}

.moblie-menu-box {
  justify-content: center;
  align-items: center;
  position: fixed;
  display: flex;
  flex-direction: column;
  height: 0vh;
  width: 100%;
  background-color: #516765;
  opacity: 0;
  a {
    font-family: "微軟正黑體";
    font-size: 5vw;
    letter-spacing: 0.1em;
    color: white;
    margin-bottom: 10vw;
  }

  .close-menu {
    position: absolute;
    top: 5vw;
    right: 5vw;
    width: 10vw;
  }
}
</style>
