<template>
  <div class="container">
    <div class="form-box member-form-box" v-if="isLogin">
      <div class="title-box">
        <div class="title">會員資料</div>
      </div>

      <div class="form-input-box">
        <div class="input-box mem-type-box">
          <div class="input-title">報名組別</div>
          <select name="mem-type" required v-model="memberType">
            <option value="" selected disabled>報名組別</option>
            <option value="1">永續組</option>
            <option value="2">國際組</option>
          </select>
        </div>
        <div class="input-box company-name-box">
          <div class="input-title">公司名稱</div>
          <input
            type="text"
            name="company-name"
            placeholder="公司名稱"
            required
            autocomplete="off"
            v-model="memberCompanyName"
          />
        </div>

        <div class="input-box mem-name-box">
          <div class="input-title">聯絡人姓名</div>
          <input
            type="text"
            name="mem-name"
            placeholder="聯絡人姓名"
            v-model="memberName"
            autocomplete="off"
            required
          />
        </div>

        <div class="input-box mem-phone-box">
          <div class="input-title">聯絡人手機</div>
          <input
            type="text"
            name="mem-phone"
            placeholder="聯絡人手機"
            v-model="memberPhone"
            @keypress="isNumber($event)"
            autocomplete="off"
            required
          />
        </div>

        <div class="input-box mem-id-box">
          <div class="input-title">帳號(統一編號)</div>
          <input
            type="text"
            name="mem-id"
            placeholder="帳號(統一編號)"
            v-model="memberID"
            autocomplete="off"
            readonly
            disabled
            required
          />
        </div>

        <div class="input-box mem-mail-box">
          <div class="input-title">信箱</div>
          <input
            type="text"
            name="mem-mail"
            placeholder="信箱"
            v-model="memberEmail"
            autocomplete="off"
            required
          />
        </div>
      </div>

      <div class="button-box">
        <div class="cancel-box">
          <button id="cancel-btn" @click="sendLogOutlInfo()">登出</button>
        </div>

        <div class="confirm-box">
          <button id="confirm-btn" @click="sendChangeInfo()">確認</button>
        </div>
      </div>
    </div>

    <!-- 登入tab -->
    <div
      class="form-box login-form-box"
      v-if="!isLogin"
      v-show="switchForm === 1"
    >
      <div class="title-box">
        <div class="title">登入</div>
        <div
          class="register-btn tab-btn"
          :key="2"
          :class="{ select: switchForm === 2 }"
          @click="selected(2)"
        >
          註冊
        </div>
      </div>

      <div class="form-input-box">
        <div class="input-box mem-id-box">
          <div class="input-title">帳號</div>
          <input
            autocomplete="off"
            type="text"
            name="mem-id"
            @keypress="isNumber($event)"
            placeholder="(統一編號)"
            v-model="loginMemID"
            required
          />
        </div>
        <div class="input-box mem-pwd-box">
          <div class="input-title">密碼</div>
          <input
            autocomplete="off"
            type="password"
            name="mem-pwd"
            placeholder="密碼"
            v-model="loginMemPwd"
            required
          />
        </div>
      </div>

      <div class="button-box login-forget-box">
        <div class="login-send-box">
          <button id="login-send-btn" @click="sendLoginInfo()">登入</button>
        </div>
        <div class="forget-pwd-box">
          <button id="forget-btn">
            <router-link to="/forget"> 忘記密碼 </router-link>
          </button>
        </div>
      </div>
    </div>

    <!-- 註冊tab -->
    <div
      class="form-box register-form-box"
      v-if="!isLogin"
      v-show="switchForm === 2"
    >
      <div class="title-box">
        <div class="title">註冊</div>
        <div
          class="login-btn tab-btn"
          :key="1"
          :class="{ select: switchForm === 1 }"
          @click="selected(1)"
        >
          登入
        </div>
      </div>

      <div class="form-input-box">
        <div class="input-box mem-type-box">
          <div class="input-title">報名組別</div>
          <select name="mem-type" required v-model="registerMemberType">
            <option value="" selected disabled>報名組別</option>
            <option value="1">永續組</option>
            <option value="2">國際組</option>
          </select>
        </div>
        <div class="input-box mem-mail-box">
          <div class="input-title">信箱</div>
          <input
            type="text"
            name="mem-mail"
            placeholder="信箱"
            v-model="registerMemberEmail"
            autocomplete="off"
            required
          />
          <span class="input-error-text" v-show="wrongEmailMsg"
            >信箱格式錯誤</span
          >
        </div>

        <div class="input-box company-name-box">
          <div class="input-title">公司名稱</div>
          <input
            type="text"
            name="company-name"
            placeholder="公司名稱"
            v-model="registerMemberCompanyName"
            autocomplete="off"
            required
          />
          <span class="input-error-text" v-show="wrongCompanyNameMsg"
            >公司名稱格式錯誤</span
          >
        </div>
        <div class="input-box mem-name-box">
          <div class="input-title">聯絡人</div>
          <input
            type="text"
            name="mem-name"
            placeholder="姓名"
            required
            v-model="registerMemberName"
            autocomplete="off"
          />
          <span class="input-error-text" v-show="wrongMemNameMsg"
            >姓名格式錯誤</span
          >
        </div>
        <div class="input-box mem-phone-box">
          <div class="input-title">聯絡人手機</div>
          <input
            type="phone"
            name="mem-phone"
            placeholder="聯絡人手機"
            required
            v-model="registerMemberPhone"
            autocomplete="off"
            @keypress="isNumber($event)"
            inputmode="numeric"
          />
        </div>
        <div class="input-box mem-id-box">
          <div class="input-title">帳號</div>
          <input
            type="text"
            name="mem-id"
            @keypress="isNumber($event)"
            placeholder="帳號(統一編號)"
            v-model="registerMemberId"
            required
            autocomplete="off"
          />
          <span class="input-error-text" v-show="wrongMemIdMsg"
            >請勿輸入空格</span
          >
        </div>
        <div class="input-box mem-pwd-box">
          <div class="input-title">密碼</div>
          <input
            type="password"
            name="mem-pwd"
            placeholder="密碼"
            v-model="registerMemberPwd"
            required
            autocomplete="off"
          />
          <span class="input-error-text" v-show="wrongPwdMsg"
            >密碼格式錯誤</span
          >
        </div>
      </div>

      <div class="button-box">
        <div class="register-send-box">
          <button
            id="register-send-btn"
            @click="sendRegisterInfo()"
            type="submit"
          >
            註冊
          </button>
        </div>
      </div>
    </div>

    <div class="bg-logo-box" v-if="!isMobile">
      <img src="../assets/images/title.png" alt="title" srcset="" />
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import axios from "axios";
import router from "@/router";

export default {
  name: "MemberView",
  data() {
    return {
      isMobile: true,
      isLogin: false,
      wrongEmail: true, // false => 沒錯
      wrongEmailMsg: false,

      wrongPhone: true, // false => 沒錯
      wrongPhoneMsg: false,

      wrongCompanyName: true, // false => 沒錯
      wrongCompanyNameMsg: false,

      wrongPwd: true, // false => 沒錯
      wrongPwdMsg: false,

      wrongMemName: true, // false => 沒錯
      wrongMemNameMsg: false,

      wrongMemId: true, // false => 沒錯
      wrongMemIdMsg: false,

      isDisableSend: true, // false => 不能送出

      memberInfo: [{}],
      memberType: 0,
      memberCompanyName: "",
      //帳號(統一編號)
      memberID: 0,
      memberEmail: "",

      JWTtokenSession: "",
      JWTtokenLocal: "",
      //登入帳密
      loginMemID: "",
      loginMemPwd: "",

      switchForm: 1,

      msg: [],
      registerMemberType: 1,
      registerMemberEmail: "",
      registerMemberCompanyName: "",
      registerMemberName: "",
      registerMemberPhone: "",
      registerMemberPwd: "",
      //帳號(統一編號)
      registerMemberId: "",

      updateId: "",
      userNameChange: "",
    };
  },

  mounted() {
    this.onResize();
    this.getMemberInfo();

    window.addEventListener("resize", this.onResize, { passive: true });
  },
  watch: {
    registerMemberPwd(newValue) {
      this.isPwdValid(newValue);
    },

    registerMemberEmail(newValue) {
      this.isEmailValid(newValue);
    },
    registerMemberCompanyName(newValue) {
      this.isCompanyNameValid(newValue);
    },

    registerMemberPhone(newValue) {
      this.isPhoneValid(newValue);
    },

    registerMemberId(newValue) {
      this.isMemIdValid(newValue);
    },

    registerMemberName(newValue) {
      this.isMemNameValid(newValue);
    },
  },
  methods: {
    onResize() {
      this.isMobile = window.innerWidth <= 1024;
    },

    selected(id) {
      this.switchForm = id;
    },

    getMemberInfo() {
      axios
        .get("https://back-longterm.srl.tw/ajax/member.php", {
          headers: {
            Authorization: `Bearer ${sessionStorage["jwt"]}`,
            "Refresh-Token": localStorage["refresh_jwt"],
          },
        })
        .then((res) => {
          // console.log(res,'getmeminfo');

          if (res.data.success === true) {
            this.isLogin = true;
            this.memberInfo = Object.freeze(res.data);
            this.memberID = this.memberInfo.data.mem_id;
            this.memberType = this.memberInfo.data.mem_type;
            this.memberCompanyName = this.memberInfo.data.company_name;
            this.memberEmail = this.memberInfo.data.mem_mail;
            this.memberPhone = this.memberInfo.data.mem_phone;
            this.memberName = this.memberInfo.data.mem_name;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    isEmailValid(value) {
      const emailRe = /^[^@]+@\w+(\.\w+)+\w$/;

      if (emailRe.test(value)) {
        this.wrongEmail = false;
        this.wrongEmailMsg = false;
      } else {
        this.wrongEmail = true;
        this.wrongEmailMsg = true;
      }
    },

    isCompanyNameValid(value) {
      const spaceRe = /\s/g;
      if (!spaceRe.test(value)) {
        this.wrongCompanyName = false;
        this.wrongCompanyNameMsg = false;
      } else {
        this.wrongCompanyName = true;
        this.wrongCompanyNameMsg = true;
      }
    },

    isPhoneValid(value) {
      const phoneRe = /^[0-9]\d*$|^$/;
      if (phoneRe.test(value)) {
        this.wrongPhone = false;
        this.wrongPhoneMsg = false;
      } else {
        this.wrongPhone = true;
        this.wrongPhoneMsg = true;
      }
    },

    isNumber(e) {
      if (!/^[0-9]+$/.test(e.key) || e.key === ".") return e.preventDefault();
    },

    isMemIdValid(value) {
      const spaceRe = /\s/g;
      if (!spaceRe.test(value)) {
        this.wrongMemId = false;
        this.wrongMemIdMsg = false;
      } else {
        this.wrongMemId = true;
        this.wrongMemIdMsg = true;
      }
    },

    isPwdValid(value) {
      const spaceRe = /\s/g;
      if (!spaceRe.test(value)) {
        this.wrongPwd = false;
        this.wrongPwdMsg = false;
      } else {
        this.wrongPwd = true;
        this.wrongPwdMsg = true;
      }
    },

    isMemNameValid(value) {
      const spaceRe = /\s/g;
      if (!spaceRe.test(value)) {
        this.wrongMemName = false;
        this.wrongMemNameMsg = false;
      } else {
        this.wrongMemName = true;
        this.wrongMemNameMsg = true;
      }
    },

    sendRegisterInfo() {
      const data = {
        mem_type: this.registerMemberType,
        mem_mail: this.registerMemberEmail,
        company_name: this.registerMemberCompanyName,
        mem_name: this.registerMemberName,
        mem_phone: this.registerMemberPhone,
        mem_pwd: this.registerMemberPwd,
        mem_id: this.registerMemberId,
        type: "singup",
      };

      if (
        this.wrongEmail === false &&
        this.wrongPhone === false &&
        this.wrongCompanyName === false &&
        this.wrongPwd === false &&
        this.wrongMemName === false &&
        this.wrongMemId === false
      ) {
        axios
          .post("https://back-longterm.srl.tw/ajax/member.php", data)
          .then((res) => {
            if (res.data.success === true) {
              Swal.fire({
                title: res.data.msg,
                icon: "success",
                background: "#f8ecd9",
                color: "#3e5a59",
                confirmButtonColor: "#3e5a59",
              }).then((result) => {
                if (result.isConfirmed) {
                  router.push("/");
                }
              });
            } else {
              Swal.fire({
                title: res.data.msg,
                icon: "error",
                background: "#f8ecd9",
                color: "#3e5a59",
                confirmButtonColor: "#3e5a59",
              });
            }
          })
          .catch((error) => {
            console.log(error);
            Swal.fire({
              title: `註冊失敗！ errorCode:${error.response.status}`,
              icon: "error",
              background: "#f8ecd9",
              color: "#3e5a59",
              confirmButtonColor: "#3e5a59",
            });
          });
      } else {
        console.log(
          this.wrongEmail &&
            this.wrongPhone &&
            this.wrongCompanyName &&
            this.wrongPwd &&
            this.wrongMemName &&
            this.wrongMemId !== true
        );
        Swal.fire({
          title: "請檢查輸入的資料是否有誤",
          icon: "error",
          background: "#f8ecd9",
          color: "#3e5a59",
          confirmButtonColor: "#3e5a59",
        });
      }
    },

    //登入
    sendLoginInfo() {
      const data = {
        mem_id: this.loginMemID,
        mem_pwd: this.loginMemPwd,
      };
      if (this.loginMemID === "" || this.loginMemPwd === "") {
        Swal.fire({
          title: "請檢查輸入的資料是否有誤",
          icon: "error",
          background: "#f8ecd9",
          color: "#3e5a59",
          confirmButtonColor: "#3e5a59",
        });
        return;
      }
      axios
        .post("https://back-longterm.srl.tw/ajax/login.php", data)
        .then((res) => {
          if (res.data.success === true) {
            this.JWTtokenSession = res.data.jwt;
            this.JWTtokenLocal = res.data.refresh_jwt;

            localStorage.setItem("refresh_jwt", res.data.refresh_jwt);
            sessionStorage.setItem("jwt", res.data.jwt);
            Swal.fire({
              title: res.data.msg,
              icon: "success",
              background: "#f8ecd9",
              color: "#3e5a59",
              confirmButtonColor: "#3e5a59",
            }).then((result) => {
              if (result.isConfirmed) {
                router.push("/");
              }
            });
          } else {
            Swal.fire({
              title: res.data.msg,
              icon: "error",
              background: "#f8ecd9",
              color: "#3e5a59",
              confirmButtonColor: "#3e5a59",
            });
          }
        })
        .catch((error) => {
          console.log(error);
          Swal.fire({
            title: `登入失敗！ errorCode:${error.response.status}`,
            icon: "error",
            background: "#f8ecd9",
            color: "#3e5a59",
            confirmButtonColor: "#3e5a59",
          });
        });
    },

    //修改會員資料
    sendChangeInfo() {
      const newData = {
        mem_type: this.memberType,
        company_name: this.memberCompanyName,
        mem_mail: this.memberEmail,
        mem_name: this.memberName,
        mem_phone: this.memberPhone,
      };
      const headers = {
        Authorization: `Bearer ${sessionStorage["jwt"]}`,
        "Refresh-Token": localStorage["refresh_jwt"],
      };
      axios
        .put("https://back-longterm.srl.tw/ajax/member.php", newData, {
          headers,
        })
        .then((res) => {
          console.log(res);
          Swal.fire({
            title: "修改成功！",
            icon: "success",
            background: "#f8ecd9",
            color: "#3e5a59",
            confirmButtonColor: "#3e5a59",
          }).then((result) => {
            if (result.isConfirmed) {
              this.getMemberInfo();
              // router.push("/");
            }
          });
        })
        .catch((error) => {
          console.log(error);
          Swal.fire(`修改失敗！ errorCode:${error.response.status}`);
        });
    },

    sendLogOutlInfo() {
      localStorage.removeItem("refresh_jwt");
      sessionStorage.removeItem("jwt");
      Swal.fire({
        title: "已登出會員",
        background: "#f8ecd9",
        color: "#3e5a59",
        confirmButtonColor: "#3e5a59",
      }).then((result) => {
        if (result.isConfirmed) {
          router.push("/");
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/scss/_mixin.scss";
@import "~sweetalert2/src/variables";
@import "~sweetalert2/src/sweetalert2";
.container {
  width: 100%;
  position: relative;
  display: flex;
  padding: 10vw;
  margin: auto;
  height: 100vh;

  @media all and (max-width: 1024px) {
    height: auto;
    padding: 0vw;
    padding-top: 20vw;
  }

  @media all and (max-width: 500px) {
    height: auto;
    padding: 0vw;
    padding-top: 28vw;
  }
  .form-box {
    display: flex;
    width: 80%;
    margin: 0 auto;
    justify-content: center;
    flex-direction: column;
    @media all and (max-width: 1440px) {
      width: 100%;
    }

    .title-box {
      width: 80%;
      position: relative;
      text-align: center;
      margin: 0 auto;
      border-bottom: 1px solid #fbf1b9;
      padding-bottom: 1vw;
      margin-bottom: 3.5vw;
      display: flex;
      justify-content: center;
      @media all and (max-width: 1920px) {
        width: 70%;
      }

      @media all and (max-width: 1024px) {
        width: 85%;
        margin-bottom: 7vw;
        padding-bottom: 2vw;
      }

      .title {
        @include titleZH();
        writing-mode: inherit;
        color: #fbf1b9;
      }

      .tab-btn {
        @include content();
        position: absolute;
        right: 0px;
        color: #fff;
        top: 0.6vw;
        cursor: pointer;

        @media all and (max-width: 1024px) {
          top: 0vw;
        }
      }
    }

    .form-input-box {
      display: flex;
      width: 100%;
      flex-wrap: wrap;
      flex-direction: column;
      margin: 0 auto;
      justify-content: center;
      @media all and (max-width: 1024px) {
        width: 90%;
      }

      :disabled {
        opacity: 0.7;
      }
      .input-box {
        display: flex;
        width: 50%;
        padding: 0vw 2vw;
        margin: 0 auto;
        justify-content: space-evenly;
        padding-bottom: 2.5vw;
        position: relative;
        @media all and (max-width: 1024px) {
          width: 100%;
          justify-content: left;
          padding-bottom: 5vw;
        }
        .input-label {
          display: flex;
          width: 50%;
          flex-direction: row-reverse;
          justify-content: space-between;

          width: 70%;
          justify-content: space-evenly;
          @media all and (max-width: 1024px) {
            flex-direction: column-reverse;
            width: 55%;
          }
          input {
            display: none;
          }

          .input-file-btn {
            display: flex;
            background-color: #3e5a59;
            @include content();
            font-weight: 600;
            color: #fff;
            cursor: pointer;
            padding: 0vw 1vw;
            border-radius: 0.3vw;

            @media all and (max-width: 1920px) {
              border-radius: 0.45vw;
            }

            @media all and (max-width: 1440px) {
              line-height: 2.2vw;
            }

            @media all and (max-width: 1024px) {
              line-height: 7vw;
              width: 21vw;
              text-align: center;
              display: block;
              border-radius: 1.2vw;
              margin-top: 1.5vw;
            }
          }

          span {
            @include content();
            color: #3e5a59;
            font-weight: 600;
            background-color: #f8ecd9;
            border-radius: 0.3vw;
            padding: 0vw 1vw;
            width: 55%;

            @media all and (max-width: 1920px) {
              border-radius: 0.45vw;
              width: 58%;
            }

            @media all and (max-width: 1440px) {
              padding-top: 0.1vw;
            }

            @media all and (max-width: 1024px) {
              width: 100%;
              border-radius: 1.2vw;
              padding: 0vw 3vw;
              line-height: 7vw;
            }
            @media all and (max-width: 500px) {
              padding: 1.5vw 3vw;
            }
          }
        }
        .input-title {
          @include dateText();
          color: #f8ecd9;
          font-weight: 600;
          width: 35%;

          @media all and (max-width: 1024px) {
            width: 45%;
            align-self: center;
          }
        }
        .input-error-text {
          color: rgb(255, 0, 0);
          font-size: 0.8vw;
          position: absolute;
          top: 0vw;
          right: 5vw;
          letter-spacing: 0.1em;
          font-family: "微軟正黑體";
          @media all and (max-width: 1024px) {
            font-size: 3.5vw;
          }
        }

        input {
          width: 40%;
          background-color: #f8ecd9;
          @include content();
          color: #3e5a59;
          font-weight: 600;
          border: none;
          border-radius: 0.3vw;
          padding: 0vw 1vw;
          &::placeholder {
            @include content();
            color: #3e5a59cc;
            font-weight: 600;
          }

          @media all and (max-width: 1920px) {
            width: 45%;
            border-radius: 0.45vw;
          }

          @media all and (max-width: 1024px) {
            width: 55%;
            border-radius: 1.2vw;
            padding: 0vw 3vw;
            line-height: 7vw;
          }

          @media all and (max-width: 500px) {
            padding: 1.5vw 3vw;
          }
        }

        select {
          width: 40%;
          background-color: #f8ecd9;
          @include content();
          color: #3e5a59;
          font-weight: 600;
          border: none;
          border-radius: 0.3vw;
          padding: 0vw 1vw;
          appearance: none;
          background-image: url("../assets/images/select_arrow.png");
          background-repeat: no-repeat;
          background-position: right 0.7rem top 50%;
          background-size: 1.2rem auto;
          cursor: pointer;

          @media all and (max-width: 1920px) {
            background-size: 1rem auto;
            width: 45%;
            border-radius: 0.45vw;
          }

          @media all and (max-width: 1440px) {
            background-size: 0.7rem auto;
          }

          @media all and (max-width: 1024px) {
            width: 55%;
            border-radius: 1.2vw;
            padding: 0vw 3vw;
          }

          @media all and (max-width: 500px) {
            padding: 1.5vw 3vw;
          }
        }
      }
    }

    .button-box {
      display: flex;
      width: 100%;
      justify-content: center;
      margin-top: 5vw;

      @media all and (max-width: 1024px) {
        margin-top: 13vw;
      }
      .cancel-box {
        #cancel-btn {
          @include buttonText();
          width: 9vw;
          color: #fff;
          background: none;
          border: 1px solid #fff;
          border-radius: 0.3vw;
          line-height: 2vw;
          cursor: pointer;

          @media all and (max-width: 1920px) {
            border-radius: 0.45vw;
          }

          @media all and (max-width: 1440px) {
            line-height: 2.2vw;
          }

          @media all and (max-width: 1024px) {
            width: 28vw;
            line-height: 7vw;
            border-radius: 1.2vw;
          }

          @media all and (max-width: 500px) {
            line-height: 8.7vw;
            width: 30vw;
          }

          &:hover {
            background: #fff;
            color: #3e5a59;
          }
        }
      }

      .confirm-box {
        margin-left: 2vw;
        @media all and (max-width: 500px) {
          margin-left: 5vw;
        }
        #confirm-btn {
          @include buttonText();
          width: 9vw;
          border: none;
          background-color: #fbf1b9;
          border-radius: 0.3vw;
          line-height: 2vw;
          cursor: pointer;

          @media all and (max-width: 1920px) {
            border-radius: 0.45vw;
          }

          @media all and (max-width: 1440px) {
            line-height: 2.2vw;
          }

          @media all and (max-width: 1024px) {
            width: 28vw;
            line-height: 7vw;
            border-radius: 1.2vw;
          }

          @media all and (max-width: 500px) {
            line-height: 9vw;
            width: 30vw;
          }
        }
      }

      .login-send-box {
        #login-send-btn {
          @include buttonText();
          width: 9vw;
          border: none;
          background-color: #fbf1b9;
          border-radius: 0.3vw;
          line-height: 2vw;
          cursor: pointer;

          @media all and (max-width: 1920px) {
            border-radius: 0.45vw;
          }

          @media all and (max-width: 1440px) {
            line-height: 2.2vw;
          }

          @media all and (max-width: 1024px) {
            width: 28vw;
            line-height: 7vw;
            border-radius: 1.2vw;
          }

          @media all and (max-width: 500px) {
            line-height: 9vw;
            width: 30vw;
          }
        }
      }

      .register-send-box {
        #register-send-btn {
          @include buttonText();
          width: 9vw;
          border: none;
          background-color: #fbf1b9;
          border-radius: 0.3vw;
          line-height: 2vw;
          cursor: pointer;

          @media all and (max-width: 1920px) {
            border-radius: 0.45vw;
          }

          @media all and (max-width: 1440px) {
            line-height: 2.2vw;
          }

          @media all and (max-width: 1024px) {
            width: 28vw;
            line-height: 7vw;
            border-radius: 1.2vw;
          }

          @media all and (max-width: 500px) {
            line-height: 9vw;
            width: 30vw;
          }
        }

        #register-send-btn:disabled {
          opacity: 0.4;
        }
      }
    }
    .login-forget-box {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 100%;
      text-align: center;

      #forget-btn {
        background: none;
        border: none;
        font-weight: bold;
        margin-top: 1vw;
        a {
          @include content();
          color: white;
        }
      }
    }
  }

  .login-form-box {
    .form-input-box {
      .input-box {
        justify-content: center;

        @media all and (max-width: 1024px) {
          justify-content: left;
        }
        .input-title {
          width: 18%;
        }

        input {
          @media all and (max-width: 1024px) {
            width: 80%;
          }
        }
      }
    }
  }

  .register-form-box {
    .form-input-box {
      flex-direction: row;
      justify-content: left;
      .input-box {
        margin: initial;
        padding-bottom: 2.5vw;
        @media all and (max-width: 500px) {
          margin-bottom: 7vw;
        }
      }
    }
  }

  .bg-logo-box {
    position: absolute;
    right: 4vw;
    bottom: 3vw;
    img {
      width: 10vw;
      opacity: 0.5;
    }
  }
}

.swal2-container {
  .swal2-popup {
    background-color: #f8ecd9 !important;

    .swal2-title {
      color: #3e5a59 !important;
    }
  }

  .swal2-actions {
    .btn-success {
      background-color: #3e5a59 !important;
    }
  }
}
</style>
