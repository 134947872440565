<template>
  <KeyVision />
  <YtNews />
  <IntroductionCard />
  <OriginCard />
  <TimeCourse />
  <ContestRules />
  <GradingRules />
  <VideoBanner />
  <ReviewTeam />
  <ContactUs />
</template>

<script>
// @ is an alias to /src
import KeyVision from "@/components/KeyVision.vue";
import YtNews from "@/components/YtNews.vue";
import IntroductionCard from "@/components/IntroductionCard.vue";
import OriginCard from "@/components/OriginCard.vue";
import TimeCourse from "@/components/TimeCourse.vue";
import ContestRules from "@/components/ContestRules.vue";
import GradingRules from "@/components/GradingRules.vue";
import VideoBanner from "@/components/VideoBanner.vue";
import ReviewTeam from "@/components/ReviewTeam.vue";
import ContactUs from "@/components/ContactUs.vue";

export default {
  name: "HomeView",
  components: {
    KeyVision,
    YtNews,
    IntroductionCard,
    OriginCard,
    TimeCourse,
    ContestRules,
    GradingRules,
    VideoBanner,
    ReviewTeam,
    ContactUs,
  },
};
</script>

<style>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
  list-style: none;
}

body {
  background-color: #5e7775;
}
</style>
