<template>
  <div class="container" id="timecourse">
    <img
      v-if="!isMobile"
      class="time-bg"
      src="../assets/images/time_bg.png"
      alt="time_bg"
      srcset=""
    />

    <div class="time-list-box">
      <div class="time-list-title">競賽時程</div>
      <div class="time-list-content">
        <div class="list">
          <div class="date">
            2024.3.1 (五)
            <div class="circle">
              <img
                src="../assets/images/table_circle.svg"
                alt="table_circle"
                srcset=""
              />
            </div>
          </div>
          <div class="list-content">
            <div class="list-title">競賽啟動 | 開始收件</div>
            <div class="content">
              由企業聯絡人將備審資料上傳至網站即可<br />
              兩份資料：1.報名表2.不限格式之公司簡介
            </div>
          </div>
        </div>

        <div class="list">
          <div class="date">
            2024.7.15 (一)
            <span class="date-small">下午5:00前</span>
            <div class="circle">
              <img
                src="../assets/images/table_circle.svg"
                alt="table_circle"
                srcset=""
              />
            </div>
          </div>
          <div class="list-content">
            <div class="list-title no-content-title">繳件截止</div>
            <!-- <div class="content" v-if="!isMobile"></div> -->
          </div>
        </div>

        <div class="list">
          <div class="date">
            2024.7.26 (五)
            <span class="date-small">下午5:00前</span>
            <div class="circle">
              <img
                src="../assets/images/table_circle.svg"
                alt="table_circle"
                srcset=""
              />
            </div>
          </div>
          <div class="list-content">
            <div class="list-title">初選結果公佈</div>
            <div class="content">
              評審委員依評選辦法評分是否入選(一組選10家，共20家) *
              此階段將針對入圍公司(各組前十名)進行是否有不良商業紀錄之審核，若發生有公司被取消資格狀況，將依名次遞補
            </div>
          </div>
        </div>

        <div class="list">
          <div class="date sp" id="date-box">
            <div class="date-1" v-if="!isMobile">
              2024.8.6 (二)
              <div class="circle">
                <img
                  src="../assets/images/table_circle.svg"
                  alt="table_circle"
                  srcset=""
                />
              </div>
            </div>
            <div class="date-2" v-if="!isMobile">
              2024.8.7 (三)
              <div class="circle">
                <img
                  src="../assets/images/table_circle.svg"
                  alt="table_circle"
                  srcset=""
                />
              </div>
            </div>
            <div class="date-moblie" v-if="isMobile">
              2024.8.6 (二)國際組 2024.8.7 (三)永續組
            </div>
          </div>
          <div class="list-content">
            <div class="list-title">決賽 | 實體簡報</div>
            <div class="content">
              入選企業簡報，每組10分鐘＋評審詢答15分鐘＝25分鐘。採【實體簡報】方式進行，屆時會再提供詳細之議程內容
            </div>
          </div>
        </div>

        <div class="list">
          <div class="date" id="date-box">
            <div class="date-1" v-if="!isMobile">
              2024.8.22 (四) <br />
              <div class="date-text">~</div>
              <div class="circle">
                <img
                  src="../assets/images/table_circle.svg"
                  alt="table_circle"
                  srcset=""
                />
              </div>
            </div>
            <div class="date-2" v-if="!isMobile">
              2024.8.30 (五)
              <div class="circle">
                <img
                  src="../assets/images/table_circle.svg"
                  alt="table_circle"
                  srcset=""
                />
              </div>
            </div>

            <div class="date-moblie" v-if="isMobile">
              2024.8.22 (四)-2024.8.30 (五)
            </div>
          </div>
          <div class="list-content">
            <div class="list-title">決賽 | 企業實地訪查</div>
            <div class="content">由評審委員組成的工作小組進行企業參訪</div>
          </div>
        </div>

        <div class="list">
          <div class="date">
            2024.10.2 (三)
            <div class="circle">
              <img
                src="../assets/images/table_circle.svg"
                alt="table_circle"
                srcset=""
              />
            </div>
          </div>
          <div class="list-content">
            <div class="list-title">頒獎典禮暨茶會</div>
            <div class="content">
              邀請進入決賽之企業出席，屆時會再提供詳細之內容
            </div>
          </div>
        </div>
      </div>
    </div>

    <img
      class="time-bg2"
      src="../assets/images/time_bg2.png"
      alt="time_bg2"
      srcset=""
    />
  </div>
</template>

<script>
export default {
  name: "TimeCourse",

  data() {
    return {
      isMobile: true,
    };
  },

  mounted() {
    this.onResize();

    window.addEventListener("resize", this.onResize, { passive: true });
  },

  methods: {
    onResize() {
      this.isMobile = window.innerWidth <= 1024;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/scss/_mixin.scss";
.container {
  width: 100%;
  position: relative;
  //   height: 100vh;
  display: flex;
  margin-top: 3vw;
  overflow: hidden;

  @media all and (max-width: 1024px) {
    margin-bottom: 14vw;
    padding-bottom: 9vw;
    margin-top: 10vw;
  }
  .time-bg {
    position: absolute;
    left: 0px;
    width: 25%;

    @media all and (max-width: 1440px) {
      top: 5vw;
    }
  }

  .time-list-box {
    display: flex;
    font-family: "Noto Sans TC";
    flex-direction: column;
    margin: 0 auto;
    margin-top: 6vw;
    width: 50%;
    margin-bottom: 6vw;
    z-index: 1;
    @media all and (max-width: 1024px) {
      width: 85%;
    }
    .time-list-title {
      color: #fbf1b9;
      font-size: 36pt;
      font-size: 1.5vw;
      letter-spacing: 0.15em;
      background-color: #8c9484;
      text-align: center;
      line-height: 3.4vw;
      border-radius: 0.5vw 0.5vw 0px 0px;
      margin-bottom: 0.3vw;

      @media all and (max-width: 1024px) {
        font-size: 20px;
        line-height: 10vw;
        border-radius: 1.5vw 1.5vw 0px 0px;
        margin-bottom: 1vw;
      }
    }

    .time-list-content {
      width: 100%;
      background-image: url(../assets/images/table_bg.png);
      background-size: cover;
      display: flex;
      flex-direction: column;
      padding: 1vw 2vw;
      padding-bottom: 2vw;
      border-radius: 0px 0px 0.5vw 0.5vw;

      @media all and (max-width: 1024px) {
        border-radius: 0px 0px 1.5vw 1.5vw;
        padding: 0vw;
        background-image: none;
      }

      .list {
        display: flex;
        padding-top: 1vw;

        @media all and (max-width: 1024px) {
          flex-direction: column;
          padding-top: 0vw;
        }
        .date {
          @include dateText();
          color: #f9edda;
          width: 28.4%;
          display: flex;
          justify-content: space-between;
          position: relative;
          @media all and (max-width: 1920px) {
            width: 29%;
          }

          @media all and (max-width: 1024px) {
            background-color: #4b6361;
            width: 100%;
            text-align: center;
            justify-content: center;
            line-height: 11vw;
            flex-direction: column;
          }

          .date-small {
            position: absolute;
            @include content();
            color: #e7dd67;
            top: 1.5vw;
            left: 2vw;

            @media all and (max-width: 1920px) {
              left: 1vw;
            }

            @media all and (max-width: 1024px) {
              position: relative;
              text-align: center;
              top: 0vw;
              left: 0vw;
              margin-top: -3vw;
              margin-bottom: 2vw;
              font-size: 3.3vw;
            }
          }
          .circle {
            img {
              width: 20px;

              @media all and (max-width: 1920px) {
                width: 1vw;
              }

              @media all and (max-width: 1024px) {
                display: none;
              }
            }
          }

          &.sp{
            justify-content: flex-start;

            .date-1{
              margin-bottom: 1.1vw !important;
            }
          }
        }

        #date-box {
          display: flex;
          flex-direction: column;
          position: relative;
          .date-1,
          .date-2 {
            display: flex;
            justify-content: space-between;
            margin-bottom: 1vw;
          }

          .date-1 {
            position: relative;
            margin-bottom: 2vw;
            .date-text {
              position: absolute;
              top: 1.7vw;
              left: 2.5vw;
            }
          }
        }

        .list-content {
          width: 71.6%;
          padding-left: 2.5vw;
          @media all and (max-width: 1024px) {
            background: linear-gradient(
              to bottom,
              #768c8a 1%,
              #576d6a 74%,
              #516765 100%
            );

            width: 100%;
            padding-left: 0vw;
            text-align: center;
          }
          .list-title {
            color: #bedeb5;
            letter-spacing: 0.1em;
            font-size: 24pt;
            font-size: 1vw;
            font-family: "微軟正黑體";
            font-weight: bold;
            padding-bottom: 0.3vw;

            @media all and (max-width: 1440px) {
              font-size: 18px;
            }

            @media all and (max-width: 1024px) {
              margin-top: 5vw;
              // margin-bottom: 4vw;
            }
          }

          .no-content-title {
            color: #bedeb5;
            letter-spacing: 0.1em;
            font-size: 24pt;
            font-size: 1vw;
            font-family: "微軟正黑體";
            font-weight: bold;
            padding-bottom: 1vw;
            border-bottom: 1px solid #f9edda;
            @media all and (max-width: 1440px) {
              font-size: 18px;
              border-bottom: none;
              padding-bottom: 5vw;
            }
          }

          .content {
            @include content();
            padding-bottom: 1vw;
            border-bottom: 1px solid #f9edda;

            @media all and (max-width: 1024px) {
              padding: 4vw 3vw;
              border-bottom: none;
            }
          }
        }
      }
    }
  }

  .time-bg2 {
    position: absolute;
    right: -2vw;
    width: 30%;
    bottom: 0px;
    z-index: 0;

    @media all and (max-width: 1024px) {
      width: 95%;
    }
  }
}
</style>
