<template>
  <div class="container">
    <div class="bg" v-if="!isMobile">
      <img src="../assets/images/card1_bg.png" alt="card1_bg" srcset="" />
    </div>
    <div class="title-box" v-if="!isMobile">
      <div class="title">
        <img src="../assets/images/title.png" alt="title" srcset="" />
      </div>
      <div class="date">
        <img src="../assets/images/title_date.svg" alt="title_date" srcset="" />
      </div>
    </div>
    <div class="small-title">
      <img src="../assets/images/small_title.svg" alt="" srcset="" />
    </div>

    <div class="moblie-bg" v-if="isMobile">
      <img src="../assets/images/moblie_bg.png" alt="" srcset="" />
    </div>
    <div class="moblie-title-box" v-if="isMobile">
      <div class="title">
        <img
          src="../assets/images/moblie_title1.png"
          alt="moblie_title1"
          srcset=""
        />
      </div>
      <div class="date mobileDate">
        <img
          src="../assets/images/moblie_title2.svg"
          alt="moblie_title2"
          srcset=""
        />
      </div>
    </div>
  </div>

  <!-- <div class="fix-2023link">
    <a
      href="https://longterm.acerfoundation.org.tw/2023/"
      target="_blank"
      rel="noopener noreferrer"
      >龍騰微笑獎<br />2023</a
    >
  </div> -->
</template>

<script>
export default {
  name: "KeyVision",
  data() {
    return {
      isMobile: true,
    };
  },

  mounted() {
    this.onResize();
    this.widthSize();
    window.addEventListener("resize", this.onResize, { passive: true });
  },

  methods: {
    onResize() {
      this.isMobile = window.innerWidth <= 1024;
    },
    widthSize() {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/scss/_mixin.scss";
.container {
  width: 100%;
  height: calc(var(--vh, 1vh) * 100);
  position: relative;
  overflow: hidden;
  // @media all and (max-width: 1024px) {
  //   background-image: url(../assets/images/moblie_bg.png);
  //   background-size: cover;
  //   background-position: center;
  // }
  @media all and (max-width: 1024px) {
    height: calc(var(--vh, 1vh) * 99);
  }
  .bg {
    position: absolute;
    left: 0px;
    bottom: 0px;
    z-index: 1;

    img {
      width: 100%;
    }
  }

  .title-box {
    position: absolute;
    z-index: 2;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;

    .title {
      display: flex;
      justify-content: center;

      img {
        width: 50%;

        @media all and (max-width: 1920px) {
          width: 31%;
        }
      }
    }

    .date {
      display: flex;
      justify-content: center;
      margin-top: 6vw;

      img {
        width: 90%;

        @media all and (max-width: 1920px) {
          width: 50%;
        }
      }
    }
  }

  .small-title {
    position: absolute;
    bottom: 15vw;
    left: 10vw;
    @media all and (max-width: 1024px) {
      bottom: 0vw;
      top: 14vw;
      left: auto;
      right: 5vw;
      text-align: right;
    }

    @media all and (max-width: 500px) {
      top: 20vw;
    }
    img {
      width: 150%;

      @media all and (max-width: 1920px) {
        width: 100%;
      }

      @media all and (max-width: 1024px) {
        width: 75%;
      }
    }
  }

  .moblie-bg {
    padding-top: 20.5vw;
    img {
      width: 100%;
    }
  }

  .moblie-title-box {
    position: absolute;
    z-index: 2;
    position: absolute;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -50%);

    .title {
      text-align: center;
      img {
        width: 100%;
      }
    }

    .date {
      text-align: center;

      &.mobileDate{
        img{
          padding-top: 15%;
        }
      }

      img {
        width: 100%;
      }
    }
  }
}

.fix-2023link {
  position: fixed;
  right: 3vw;
  bottom: 3vw;
  display: flex;
  justify-content: center;
  align-self: center;
  align-items: center;
  width: 4vw;
  height: 4vw;
  border-radius: 50%;
  z-index: 99;
  background: linear-gradient(
    to bottom,
    rgba(251, 241, 185, 1) 10%,
    rgba(147, 178, 141, 1) 81%
  );

  @media all and (max-width: 1920px) {
    width: 5vw;
    height: 5vw;
  }
  @media all and (max-width: 1440px) {
    width: 5.5vw;
    height: 5.5vw;
  }

  @media all and (max-width: 1024px) {
    width: 10vw;
    height: 10vw;
  }

  @media all and (max-width: 500px) {
    width: 22vw;
    height: 22vw;
  }

  a {
    color: rgb(62, 90, 89);
    text-align: center;
    font-size: 0.65vw;
    letter-spacing: 0.1em;
    font-family: "Noto Sans TC";
    font-weight: 400;

    @media all and (max-width: 1920px) {
      font-size: 0.8vw;
    }

    @media all and (max-width: 1440px) {
      font-size: 0.9vw;
    }

    @media all and (max-width: 1024px) {
      font-size: 1.7vw;
    }

    @media all and (max-width: 500px) {
      font-size: 3.5vw;
    }
  }
}
</style>
