<template>
  <div class="container">
    <div class="c3-bg-box" v-if="!isMobile">
      <img
        class="c3-bg"
        src="../assets/images/c3_bg.png"
        alt="c3_bg"
        srcset=""
      />
    </div>

    <div class="content-box">
      <div class="content content1">
        「不論公司規模大小，都可以成為國際舞台的主角！」將企業接軌國際的精神延伸至台灣中小企業，宏碁期待在快速變化的市場當中求新求變，用過往在全球落地的經驗，帶著中小企業一起加入國際馬拉松行列，為台灣企業領跑破風。宏碁集團與宏碁基金會，打造「龍騰獎」，要串連宏碁在全球50幾國的觸角，協助成熟新創落地。
      </div>
      <div class="content content2">
        ESG已是全球企業標竿，2023年是宏碁連續第十年入選DJSI，並且在S&P
        Global等權威性國際評比中屢奪佳績，不想只是自己前行，宏碁也期待藉由龍騰獎扮演企業落實ESG的推手，支持能夠幫助社會與環境相關的產品或服務。
      </div>
      <div class="content content3">
        2024年龍騰微笑競賽設立國際組與永續組，宏碁結合集團優勢，串聯跨國據點攜企業跟上世界脈動；以宏碁集團在國際的營運經驗分享、合作，協助中小企業走向國際，並做企業永續路上的最好支柱。
      </div>
    </div>
    <div class="title-box">
      <div class="title-en">INTRODUCTION</div>
      <div class="title-zh">競賽介紹</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "IntroductionCard",
  data() {
    return {
      isMobile: true,
    };
  },

  mounted() {
    this.onResize();

    window.addEventListener("resize", this.onResize, { passive: true });
  },

  methods: {
    onResize() {
      this.isMobile = window.innerWidth <= 1024;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/scss/_mixin.scss";
.container {
  width: 100%;
  position: relative;
  display: flex;
  justify-content: space-between;

  @media all and (max-width: 1440px) {
    margin-top: 10vw;
  }

  @media all and (max-width: 1024px) {
    flex-direction: column-reverse;
  }
  .c3-bg-box {
    width: 34%;
    display: inline-block;

    .c3-bg {
      width: 75%;
    }
  }

  .content-box {
    @include content();
    width: 40%;
    @media all and (max-width: 1024px) {
      width: 85%;
      margin: 0 auto;
      line-height: 6.5vw;
    }
    .content {
      margin-bottom: 2vw;
    }
  }

  .title-box {
    display: flex;
    width: 25%;
    justify-content: center;

    @media all and (max-width: 1024px) {
      width: 85%;
      flex-direction: column;
      margin: 0 auto;
      text-align: center;
      margin-bottom: 5vw;
    }
    .title-zh {
      @include titleZH();
      @media all and (max-width: 1024px) {
        display: flex;
        justify-content: center;
      }
      &::after {
        @include titleAfter();
        height: 8vw;

        @media all and (max-width: 1920px) {
          height: 7vw;
        }

        @media all and (max-width: 1024px) {
          display: none;
        }
      }

      &::before {
        content: "";
        width: 28vw;
        display: none;
        margin-right: 6vw;
        border-top: 1px solid #fff;
        align-self: center;
        @media all and (max-width: 1024px) {
          display: inline-block;
        }
      }
    }

    .title-en {
      @include titleEN();
    }
  }

  .moblie-title-box {
    text-align: center;
    margin-bottom: 5vw;
    img {
      width: 50%;
    }
  }
}
</style>
