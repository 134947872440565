<template>
  <div class="container">
    <div class="loading" v-if="loading">
      寄送中...請稍後
    </div>
    <div class="form-box">
      <div class="title">忘記密碼</div>
      <div class="form-input-box">
        <div class="input-box mem-email-box">
          <div class="input-title">報名信箱</div>

          <input
            autocomplete="off"
            type="text"
            name="mem-email"
            id=""
            placeholder="信箱"
            v-model="MemEmail"
            required
          />
          <div class="send-pwd-btn" @click="sendVerifyEmail()">寄送驗證碼</div>
        </div>
        <div class="input-box mem-verify-box">
          <div class="input-title">驗證碼</div>
          <input
            autocomplete="off"
            type="text"
            name="verify"
            id=""
            placeholder="驗證碼"
            v-model="verify"
            @keypress="isNumber($event)"
            required
          />
        </div>
        <div class="input-box mem-id-box">
          <div class="input-title">新密碼</div>
          <input
            autocomplete="off"
            type="password"
            name="mem-pwd"
            id=""
            placeholder="密碼"
            v-model="MemPwd"
            required
          />
        </div>

        <div class="input-box mem-id-box">
          <div class="input-title">確認密碼</div>
          <input
            autocomplete="off"
            type="password"
            name="mem-pwd2"
            id=""
            placeholder="確認密碼"
            v-model="MemPwd2"
            required
          />
        </div>
      </div>

      <div class="button-box">
        <div class="cancel-box">
          <!-- <button id="cancel-btn">取消</button> -->
          <router-link id="cancel-btn" to="/member">取消</router-link>
        </div>

        <div class="confirm-box">
          <button id="confirm-btn" @click="sendChangePwd()">確認</button>
        </div>
      </div>
    </div>
    <div class="bg-logo-box" v-if="!isMobile">
      <img src="../assets/images/title.png" alt="title" srcset="" />
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import axios from "axios";
import router from "@/router";

export default {
  name: "ForgetPwdView",
  data() {
    return {
      isMobile: true,
      MemEmail: "",
      verify: "",
      MemPwd: "",
      MemPwd2: "",
      loading: false
    };
  },

  mounted() {
    this.onResize();
    window.addEventListener("resize", this.onResize, { passive: true });
  },

  methods: {
    onResize() {
      this.isMobile = window.innerWidth <= 1024;
    },
    isNumber(e) {
      if (!/^[0-9]+$/.test(e.key) || e.key === ".") return e.preventDefault();
    },
    sendVerifyEmail() {

      this.loading=true;

      const data = {
        type: "check_mail",
        mem_mail: this.MemEmail,
      };
      axios
        .post("https://back-longterm.srl.tw/ajax/forget.php", data)
        .then((res) => {
          // console.log(res.data);
          if (res.data.success === false) {
            Swal.fire({
              title: res.data.msg,
              icon: "error",
              background: "#f8ecd9",
              color: "#3e5a59",
              confirmButtonColor: "#3e5a59",
            });
          } else {
            Swal.fire({
              title: "寄送成功！",
              icon: "success",
              background: "#f8ecd9",
              color: "#3e5a59",
              confirmButtonColor: "#3e5a59",
            });
          }
        })
        .catch((error) => {
          console.log(error);
          Swal.fire(`寄送失敗！ errorCode:${error.response.status}`);
        })
        .finally(() => {
          // 無論成功或失敗，都將 loading 設為 false
          this.loading = false;
        });
    },

    sendChangePwd() {
      const data = {
        mail_ch_num: this.verify,
        mem_pwd: this.MemPwd2,
        mem_mail: this.MemEmail,
      };
      axios
        .put("https://back-longterm.srl.tw/ajax/forget.php", data)
        .then((res) => {
          // console.log(res.data);
          if (res.data.success === false) {
            Swal.fire({
              title: res.data.msg,
              icon: "error",
              background: "#f8ecd9",
              color: "#3e5a59",
              confirmButtonColor: "#3e5a59",
            });
          } else {
            Swal.fire({
              title: "更換密碼成功！",
              icon: "success",
              background: "#f8ecd9",
              color: "#3e5a59",
              confirmButtonColor: "#3e5a59",
            }).then((result) => {
              if (result.isConfirmed) {
                router.push("/");
              }
            });
          }
        })
        .catch((error) => {
          console.log(error);
          Swal.fire(`更換密碼失敗！ errorCode:${error.response.status}`);
        });
    },

    sendCancelInfo() {
      // Swal.fire("修改取消！");
      Swal.fire({
        title: "重設密碼取消！",
        background: "#f8ecd9",
        color: "#3e5a59",
        confirmButtonColor: "#3e5a59",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/scss/_mixin.scss";

.container {
  width: 100%;
  position: relative;
  display: flex;
  padding: 10vw;
  margin: auto;
  // padding-bottom: 13vw;
  // padding-top: 11vw;
  height: 100vh;

  @media all and (max-width: 1024px) {
    height: auto;
    padding: 0vw;
    padding-top: 20vw;
  }

  @media all and (max-width: 500px) {
    height: auto;
    padding: 0vw;
    padding-top: 28vw;
  }

  .loading{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    background-color: #5e7775d1;
    backdrop-filter: blur(10px);
    z-index: 100;
    font-size: 19px;
    letter-spacing: 0.1em;
  }

  .form-box {
    display: flex;
    width: 80%;
    margin: 0 auto;
    justify-content: center;
    flex-direction: column;

    @media all and (max-width: 1440px) {
      width: 100%;
    }

    .title {
      width: 80%;
      @include titleZH();
      writing-mode: inherit;
      color: #fbf1b9;
      text-align: center;
      margin: 0 auto;
      border-bottom: 1px solid #fbf1b9;
      padding-bottom: 1vw;
      margin-bottom: 3.5vw;

      @media all and (max-width: 1920px) {
        width: 70%;
      }

      @media all and (max-width: 1024px) {
        width: 85%;
        margin-bottom: 7vw;
        padding-bottom: 2vw;
      }
    }

    .form-input-box {
      display: flex;
      width: 100%;
      flex-wrap: wrap;
      flex-direction: column;
      margin: 0 auto;

      @media all and (max-width: 1920px) {
        width: 90%;
        margin: 0 auto;
      }
      @media all and (max-width: 1024px) {
        flex-direction: column;
      }

      .input-box {
        display: flex;
        width: 50%;
        padding: 0vw 2vw;

        margin: 0 auto;
        margin-bottom: 2vw;
        @media all and (max-width: 1920px) {
          justify-content: space-evenly;
        }
        @media all and (max-width: 1024px) {
          width: 100%;
          justify-content: left;
          margin-bottom: 5vw;
        }

        .input-title {
          @include dateText();
          color: #f8ecd9;
          font-weight: 600;
          width: 35%;

          @media all and (max-width: 1024px) {
            width: 30%;
            align-self: center;
          }
        }

        input {
          width: 40%;
          background-color: #f8ecd9;
          @include content();
          color: #3e5a59;
          font-weight: 600;
          border: none;
          border-radius: 0.3vw;
          padding: 0vw 1vw;
          &::placeholder {
            @include content();
            color: #3e5a59cc;
            font-weight: 600;
          }

          @media all and (max-width: 1920px) {
            width: 45%;
            border-radius: 0.45vw;
          }

          @media all and (max-width: 1024px) {
            width: 70%;
            border-radius: 1.2vw;
            padding: 0vw 3vw;
            line-height: 7vw;
          }

          @media all and (max-width: 500px) {
            padding: 1.5vw 3vw;
          }
        }

        select {
          width: 40%;
          background-color: #f8ecd9;
          @include content();
          color: #3e5a59;
          font-weight: 600;
          border: none;
          border-radius: 0.3vw;
          padding: 0vw 1vw;
          appearance: none;
          background-image: url("../assets/images/select_arrow.png");
          background-repeat: no-repeat;
          background-position: right 0.7rem top 50%;
          background-size: 1.2rem auto;
          cursor: pointer;

          @media all and (max-width: 1920px) {
            background-size: 1rem auto;
            width: 45%;
            border-radius: 0.45vw;
          }

          @media all and (max-width: 1440px) {
            background-size: 0.7rem auto;
          }

          @media all and (max-width: 1024px) {
            width: 55%;
            border-radius: 1.2vw;
            padding: 0vw 3vw;
          }

          @media all and (max-width: 500px) {
            padding: 1.5vw 3vw;
          }
        }
      }

      .mem-email-box {
        position: relative;
        @media all and (max-width: 1024px) {
          padding-bottom: 13vw;
        }
        .send-pwd-btn {
          display: flex;
          position: absolute;
          background-color: #3e5a59;
          @include content();
          font-weight: 600;
          color: #fff;
          cursor: pointer;
          padding: 0vw 1vw;
          border-radius: 0.3vw;
          cursor: pointer;
          right: 2vw;

          @media all and (max-width: 1920px) {
            border-radius: 0.45vw;
            right: -4vw;
          }

          @media all and (max-width: 1440px) {
            line-height: 2.2vw;
            right: -5vw;
          }

          @media all and (max-width: 1024px) {
            bottom: 3vw;
            right: 2vw;
            line-height: 7vw;
            width: 25vw;
            text-align: center;
            display: block;
            border-radius: 1.2vw;
            margin-top: 1.5vw;
          }
        }
      }
    }

    .button-box {
      display: flex;
      width: 100%;
      justify-content: center;
      margin-top: 5vw;

      @media all and (max-width: 500px) {
        margin-top: 9vw;
      }
      .cancel-box {
        #cancel-btn {
          @include buttonText();
          display: block;
          text-align: center;
          width: 9vw;
          color: #fff;
          background: none;
          border: 1px solid #fff;
          border-radius: 0.3vw;
          line-height: 2vw;
          cursor: pointer;

          @media all and (max-width: 1920px) {
            border-radius: 0.45vw;
          }

          @media all and (max-width: 1440px) {
            line-height: 2.2vw;
          }

          @media all and (max-width: 1024px) {
            width: 28vw;
            line-height: 7vw;
            border-radius: 1.2vw;
          }

          @media all and (max-width: 500px) {
            line-height: 8.7vw;
            width: 30vw;
          }
        }
      }

      .confirm-box {
        margin-left: 2vw;
        @media all and (max-width: 500px) {
          margin-left: 5vw;
        }
        #confirm-btn {
          @include buttonText();
          width: 9vw;
          border: none;
          background-color: #fbf1b9;
          border-radius: 0.3vw;
          line-height: 2vw;
          cursor: pointer;

          @media all and (max-width: 1920px) {
            border-radius: 0.45vw;
          }

          @media all and (max-width: 1440px) {
            line-height: 2.2vw;
          }

          @media all and (max-width: 1024px) {
            width: 28vw;
            line-height: 7vw;
            border-radius: 1.2vw;
          }

          @media all and (max-width: 500px) {
            line-height: 9vw;
            width: 30vw;
          }
        }
      }
    }
  }

  .bg-logo-box {
    position: absolute;
    right: 4vw;
    bottom: 3vw;
    img {
      width: 10vw;
      opacity: 0.5;
    }
  }
}
</style>
